import React, {
  FunctionComponent,
  useRef,
  useState,
} from 'react';
// import Link from 'next/link';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { appUrl, teamUrl } from '@configs';

const FreeTrialSection: FunctionComponent = () => {
  const {
    locale,
    push,
    pathname,
  } = useRouter();

  // Language dropdown
  const [lang, setlang] = useState(false);
  const langToggle = (language?: string) => () => {
    if (language) {
      push(pathname, pathname, {
        locale: language,
        scroll: false,
      });
    }
    setlang((prevState) => !prevState);
  };

  const dropdownRef = useRef({} as HTMLDivElement);
  const dropdownBtnRef = useRef({} as HTMLButtonElement);

  const { t } = useTranslation('common');

  return (
    <div className="container md:px-6 px-0 mx-auto">
      <div className="flex flex-col md:flex-row justify-between items-center text-sm py-2 ">
        <div className="flex flex-col md:flex-row w-full md:w-fit">
          <div className="py-3 px-0 md:py-2 md:px-2 flex justify-start items-center mr-4 focus:ring-2 text-blue-dark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
            </svg>
            <a href={appUrl} target="_blank" className="font-medium mb-0 tracking-normal text-sm" rel="noreferrer">{t('participantLogin')}</a>
          </div>
          <div className="py-3 px-0 md:py-2 md:px-2 flex justify-start items-center focus:ring-2 text-blue-dark">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
            </svg>
            <a href={teamUrl} target="_blank" className="font-medium mb-0 tracking-normal text-sm" rel="noreferrer">{t('recruiterLogin')}</a>
          </div>
        </div>
        <div className="md:w-fit w-full xl:mb-0 xl:mt-0 lg:mb-0 mt-2 mb-2">
          <button
            ref={dropdownBtnRef}
            onClick={langToggle()}
            type="button"
            className="inline-flex flex items-center xl:justify-center w-fit py-2 px-0 md:px-2 focus:ring-2 text-blue-dark"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
            </svg>
            {locale?.toUpperCase()}
            <svg className="ml-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
          <div className="container relative">
            <div
              className={clsx('xl:absolute right-0 mt-2 xl:mt-2 xl:w-40 rounded-md lg:drop-shadow-lg bg-white focus:outline-none', {
                'mb-4': lang,
                hidden: !lang,
              })}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              ref={dropdownRef}
            >
              <div className="py-1" role="none">
                <div
                  onClick={langToggle('en')}
                  role="button"
                  tabIndex={-1}
                  className="font-normal text-gray-700 block px-6 py-3 cursor-pointer hover:bg-gray-100"
                >
                  English
                </div>
                <div
                  onClick={langToggle('id')}
                  role="button"
                  tabIndex={-2}
                  className="font-normal text-gray-700 block px-6 py-3 cursor-pointer hover:bg-gray-100"
                >
                  Indonesia
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeTrialSection;
