import React, {
  FunctionComponent,
  createContext,
  useContext,
  Dispatch,
  useReducer,
  useEffect,
  useState,
} from 'react';

/**
 * Local storage key for config reducer
 */
const configKey = 'algoconfig';

/**
 * CookieConfig reducer type
 */
interface ConfigType {
  ga: boolean;
  tawk: boolean;
}

type CookieConfig = ConfigType | undefined;

/**
 * Initial value and reducer
 */
const initialValue: CookieConfig = undefined;

function reducer(state: CookieConfig, action: CookieConfig) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(configKey, JSON.stringify(action));
  }

  return action;
}

/**
 * CookieConfig Context
 */
const CookieAlertContext = createContext([true, (val: boolean) => { }]);
const CookieStateContext = createContext<CookieConfig>(initialValue);
const CookieDispatchContext = createContext<Dispatch<CookieConfig>>({} as Dispatch<CookieConfig>);

/**
 * Provider for CookieConfig, used in _app.tsx
 */
const CookieConfigProvider: FunctionComponent = (props) => {
  const { children } = props;
  const [hidden, setHidden] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialValue);

  useEffect(() => {
    const ls = window.localStorage.getItem(configKey);
    if (ls) {
      try {
        const conf = JSON.parse(ls);
        if (conf && typeof conf === 'object') {
          dispatch(conf);
          return;
        }
      } catch {
        // Cancel dispatch
      }
    }
    setHidden(false);
  }, []);

  return (
    <CookieAlertContext.Provider value={[hidden, setHidden]}>
      <CookieStateContext.Provider value={state}>
        <CookieDispatchContext.Provider value={dispatch}>
          {children}
        </CookieDispatchContext.Provider>
      </CookieStateContext.Provider>
    </CookieAlertContext.Provider>
  );
};

/**
 * Context consumer, used in MainNavbar component
 */
function useCookieConfig(): [CookieConfig, Dispatch<CookieConfig>] {
  const state = useContext(CookieStateContext);
  const dispatch = useContext(CookieDispatchContext);

  return [state, dispatch];
}

function useAlertConfig() {
  return useContext(CookieAlertContext) as [boolean, (val: boolean) => void];
}

export { CookieConfigProvider, useCookieConfig, useAlertConfig };
export type { CookieConfig, ConfigType };
