import {
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';

/**
 * Custom hook that will make object able to trigger a function when
 * outside component being clicked.
 * @param ref Reference to input where outside click will be detected.
 * @param callback Callback function that will be called when outside click occured.
 * @param deps Dependency listener (optional)
 */
export function useOutsideClick(
  ref: MutableRefObject<any> | MutableRefObject<any>[],
  callback: () => void,
  deps: any[] = [],
) {
  const handleClickOutside = (e: Event) => {
    if (Array.isArray(ref)) {
      if (ref.map((r) => r?.current?.contains
        && !r.current.contains(e.target)).reduce((p, c) => p && c)) {
        callback();
      }
    } else if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, deps);
}

/**
 * Return previous value of states or props.
 * @param value Any value that will be checked.
 */
export function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
