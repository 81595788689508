import React, {
  FunctionComponent,
  MouseEvent,
} from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  /**
   * Handle copy email click
   */
  const onClickCopyEmail = (e: MouseEvent) => {
    e.preventDefault();
    const email = 'aW5mb0BhbGdvYmFzaC5jb20=';
    const text = Buffer.from(email, 'base64');
    navigator.clipboard.writeText(text.toString());
  };

  return (
    <footer className="h-full w-full bg-blue-dark">
      <div className="flex flex-col md:flex-row container container-padding mx-auto py-10 justify-between sm:items-start">
        <div className="flex items-start justify-start xs:justify-center text-left ">
          <img className="mr-5 mt-2 block" src="/images/algo-white-logo-sm.png" alt="algo-white-logo" />
          <div className="">
            <p className="opacity-100">Copyright © 2021 Algobash</p>
            <p className="font-light">Under PT Matagi Informasi Pertiwi</p>
            <p className="font-light">Indonesia</p>
            <Link href="/privacy-policy" passHref>
              <a className="font-light hover:opacity-90">{t('privacyPolicy')}</a>
            </Link>
            <span className="text-white mx-1">
              .
            </span>
            <Link href="/terms-and-conditions" passHref>
              <a className="font-light hover:opacity-90">{t('termsAndCondition')}</a>
            </Link>
          </div>
        </div>
        <div>
          <div className="mt-2 md:mt-0 md:text-right text-center">
            <Link href="https://wa.me/6285157707484/?text=Hi+Algobash%2C+I+want+to+ask+a+question">
              <a target="_blank" className="underline font-light mx-2">
                <span>+</span>
                <span>62815</span>
                <span>-</span>
                <span>1577</span>
                <span>-</span>
                <span>07484</span>
              </a>
            </Link>
            <a
              href="#copy-email"
              className="underline font-light mx-2"
              onClick={onClickCopyEmail}
              role="button"
            >
              info
              <span className="text-[10px]">(at)</span>
              algobash
              <span className="text-[10px]">(dot)</span>
              com
            </a>
          </div>
          <div className="mt-2 md:text-left text-center">
            <Link href="https://www.instagram.com/algobashofficial/">
              <a target="_blank"><img className="w-5 mx-3 inline-block" alt="instagram" src="/images/instagram.png" /></a>
            </Link>
            <Link href="https://www.facebook.com/algobashofficial/">
              <a target="_blank"><img className="w-5 mx-3 inline-block" alt="facebook" src="/images/facebook.png" /></a>
            </Link>
            <Link href="https://www.linkedin.com/company/algobash/">
              <a target="_blank"><img className="w-5 mx-3 inline-block" alt="linkedin" src="/images/linkedin.png" /></a>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
