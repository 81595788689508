import React, {
  FunctionComponent,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { FiChevronDown } from 'react-icons/fi';
import { useTranslation } from 'next-i18next';
import Swal from 'sweetalert2';
import { useRouter } from 'next/router';

import { Profile } from '@type';
import { teamUrl, appUrl } from '@configs';
import { useOutsideClick } from '@helpers/hooks';
import { postLogoutUser } from '@resources/auth';

interface Props {
  profile: Profile;
  lightMode?: boolean;
  course?: boolean;
}

const AuthButton: FunctionComponent<Props> = (props) => {
  const { profile, lightMode, course } = props;
  const { t } = useTranslation();
  const { push } = useRouter();
  const [open, setOpen] = useState(false);

  // Control dropdown state close
  const dropdownRef = useRef({} as HTMLDivElement);
  const dropdownBtnRef = useRef({} as HTMLButtonElement);
  useOutsideClick([dropdownRef, dropdownBtnRef], () => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  /**
   * Handles user log out
   */
  const onClickLogout = () => {
    setOpen(false);
    Swal.fire({
      title: t('areYouSure'),
      text: t('logoutConfirm'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('logoutyes'),
      cancelButtonText: t('logoutno'),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const result = await postLogoutUser();
        if (!result || result?.error) {
          Swal.fire({
            icon: 'error',
            title: t('logoutFailed'),
            text: result?.error?.message || t('logoutFailedDesc'),
          });
          return;
        }

        await push('/');
      },
    });
  };

  return (
    <>
      <button
        ref={dropdownBtnRef}
        onClick={() => setOpen(!open)}
        type="button"
        className={clsx('inline-flex w-full py-3 px-3.5 focus:outline-none focus:ring-2 items-center', {
          'text-blue-dark bg-white': !lightMode && !course,
          'text-white bg-blue': lightMode && !course,
          'text-blue bg-blue-lightest lg:rounded-full lg:px-5 py-1.5': course,
        })}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        {course
          ? (
            <>
              <img
                className="rounded-full h-[25px] w-auto mr-2"
                src={profile.profile_picture || ''}
                alt="profile_picture.png"
              />
              {profile.first_name}
              <div className="flex-grow lg:hidden" />
            </>
          )
          : `${t('hi')}, ${profile.first_name} `}
        <FiChevronDown className="ml-1" />
      </button>
      <div className="relative">
        <div
          className={clsx('xl:absolute right-0 mt-2 xl:mt-4 xl:w-40 rounded-md focus:outline-none bg-white lg:drop-shadow-lg', {
            'top-1': course,
            'mb-4': open,
            hidden: !open,
          })}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          ref={dropdownRef}
        >
          <Link href={course ? appUrl : teamUrl}>
            <a
              tabIndex={-1}
              onClick={() => setOpen(false)}
              role="button"
              className="text-black block px-6 py-4 cursor-pointer font-medium flex justify-between items-center hover:bg-gray-100 lg:rounded-t-md whitespace-nowrap"
            >
              Dashboard
            </a>
          </Link>
          {course && (
            <>
              {/* <Link href="/transactions">
                <a
                  tabIndex={-1}
                  onClick={() => setOpen(false)}
                  role="button"
                  className="text-black block px-6 py-4 cursor-pointer font-medium flex
                  justify-between items-center hover:bg-gray-100 lg:rounded-t-md whitespace-nowrap"
                >
                  Daftar Transaksi
                </a>
              </Link> */}
              <Link href={`${appUrl}/profile`} passHref>
                <a
                  tabIndex={-1}
                  onClick={() => setOpen(false)}
                  role="button"
                  className="text-black block px-6 py-4 cursor-pointer font-medium flex justify-between items-center hover:bg-gray-100 lg:rounded-t-md whitespace-nowrap"
                >
                  Profile
                </a>
              </Link>
            </>
          )}
          <div
            onClick={onClickLogout}
            role="button"
            tabIndex={-2}
            className="text-red-600 block px-6 py-4 cursor-pointer font-medium flex justify-between items-center hover:bg-gray-100 lg:rounded-b-md whitespace-nowrap"
          >
            {t('logout')}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthButton;
