/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import Head from 'next/head';

import Navbar, {
  NavbarProps,
} from '@layout/Navbar';
import CourseNavbar from '@layout/CourseNavbar';
import Footer from '@layout/Footer';
import CourseFooter from '@layout/CourseFooter';
import CookieConsent from './CookieConsent';

interface Props extends NavbarProps {
  pageTitle: string;
  hideFooter?: boolean;
  hideWaButton?: boolean;
  course?: boolean;
}

const Layout: React.FC<Props> = (props) => {
  const {
    children,
    pageTitle,
    hideFooter,
    course,
    ...rest
  } = props;

  return (
    <>
      {course
        ? <CourseNavbar {...rest} />
        : <Navbar {...rest} />}
      <Head>
        <title>{pageTitle}</title>
      </Head>
      {children}
      {!hideFooter && (course
        ? <CourseFooter />
        : <Footer />)}
      <CookieConsent />
    </>
  );
};

export default Layout;
