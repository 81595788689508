import React, {
  FunctionComponent,
} from 'react';
import Link from 'next/link';
import clsx from 'clsx';

interface Props {
  type: string;
  target: boolean;
  buttonLink: string;
  buttonText: string;
  customClass?: string;
}

const Button: FunctionComponent<Props> = (props: Props) => {
  const {
    type, buttonLink, target, buttonText, customClass = '',
  } = props;

  switch (type) {
    case 'button-outline-bluedark':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-outline-bluedark"
            target={target === false ? '' : '_blank'}
            className={clsx('inline-block bg-transparent border-solid border border-blue-dark hover:bg-gray-200 hover:bg-opacity-10 text-blue-dark font-medium py-3 px-4 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-outline-white':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-outline-white"
            target={target === false ? '' : '_blank'}
            className={clsx('inline-block bg-transparent border-solid border border-white hover:bg-gray-200 hover:bg-opacity-10 text-white font-medium py-2 px-4 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-green':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-green"
            target={target === false ? '' : '_blank'}
            className={clsx('bg-lime-dark hover:bg-lime-darker border-lime-dark hover:border-lime-darker border-1 border-solid inline-block text-white font-medium py-3 px-4 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-red':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-red"
            target={target === false ? '' : '_blank'}
            className={clsx('bg-[#EB0030] hover:bg-[#BA0026] border-1 border-solid inline-block text-white font-medium py-3 px-4 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-green-sm':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-green"
            target={target === false ? '' : '_blank'}
            className={clsx('bg-lime-dark hover:bg-lime-darker border-lime-dark hover:border-lime-darker border-1 border-solid inline-block text-white font-medium py-2 px-4 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-blue':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-blue"
            target={target === false ? '' : '_blank'}
            className={clsx('bg-[#092A47] hover:bg-[#004D90] border-[#0061B7] hover:border-[#0061B7] border-1 border-solid inline-block text-white font-medium py-3 px-4 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-soft-blue':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-soft-blue"
            target={target === false ? '' : '_blank'}
            className={clsx('bg-[#EDF4FB] hover:bg-[#CFDDEB] border-[#EDF4FB] hover:border-[#EDF4FB] border-1 border-solid inline-block text-[#0061B7] font-medium py-3 px-4 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-white':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-green"
            target={target === false ? '' : '_blank'}
            className={clsx('bg-white hover:opacity-80 border-white-dark hover:border-white-darker border-1 border-solid inline-block text-blue-dark font-medium py-2 px-4 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-white-xl':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-white-xl"
            target={target === false ? '' : '_blank'}
            className={clsx('inline-block text-blue bg-white font-medium text-md py-4 px-5 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-bluedark-xl':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-blue-xl"
            target={target === false ? '' : '_blank'}
            className={clsx('inline-block text-white bg-blue font-medium text-md py-4 px-5 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-bluedark-xl2':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-blue-xl"
            target={target === false ? '' : '_blank'}
            className={clsx('inline-block text-white bg-[#092A47] font-medium text-md py-4 px-5 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-red-xl':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-red-xl"
            target={target === false ? '' : '_blank'}
            className={clsx('inline-block text-white bg-[#EB0030] font-medium text-md py-4 px-5 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-lime-xl':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-green-xl"
            target={target === false ? '' : '_blank'}
            className={clsx('inline-block text-black bg-lime-light font-medium text-md py-4 px-5 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    case 'button-blue-xl':
      return (
        <Link href={buttonLink} passHref>
          <a
            type="button-blue-xl"
            target={target === false ? '' : '_blank'}
            className={clsx('inline-block text-white bg-blue-semidark font-medium text-md py-4 px-5 rounded', customClass)}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </Link>
      );
    default:
      return (
        <div />
      );
  }
};

export default Button;
