import React, {
  FunctionComponent,
  useRef,
  useState,
} from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FiChevronDown } from 'react-icons/fi';

import { appUrl, domain } from '@configs';
import { NavbarProps } from '@layout/Navbar';
import { useOutsideClick } from '@helpers/hooks';
import { useUserProfile } from '@reducers/user';
import AuthButton from '@component/AuthButton';

const CourseNavbar: FunctionComponent<NavbarProps> = (props) => {
  const { t } = useTranslation('common');
  const { profile, fullUrl } = useUserProfile();
  const {
    asPath,
    locale,
    push,
    pathname,
    query,
  } = useRouter();

  // Language dropdown
  const [lang, setlang] = useState(false);
  const langToggle = (language?: string) => () => {
    if (language) {
      push({
        pathname,
        query,
      }, asPath, {
        locale: language,
        scroll: false,
      });
    }
    setlang((prevState) => !prevState);
  };

  // Dropdown
  const dropdownRef = useRef({} as HTMLDivElement);
  const dropdownBtnRef = useRef({} as HTMLButtonElement);
  useOutsideClick([dropdownRef, dropdownBtnRef], () => {
    if (lang) {
      setlang(false);
    }
  }, [lang]);

  // Navigation Menu Dropdown
  const [navRes, setNavRes] = useState(false);
  const navToggle = () => setNavRes((prevState) => !prevState);
  const navbarRef = useRef({} as HTMLElement);
  useOutsideClick([navbarRef], () => {
    if (navRes) {
      setNavRes(false);
    }
  }, [navRes]);

  return (
    <div className="w-full z-50 fixed">
      {/* Ribbon */}
      <div className="bg-blue-dark">
        <div className="container container-padding mx-auto flex justify-between items-center text-white py-2 text-11p">
          <p className="text-lime-light font-normal mb-0 tracking-normal">
            {t('courseribbon1')}
          </p>
          <a href={domain} target="_blank" className="bg-white hover:bg-gray-200 py-1 px-3 rounded flex items-center" rel="noreferrer">
            <span className="text-black font-medium pr-0 md:pr-1">
              {`${t('visit')} `}
            </span>
            <span className="text-black font-medium hidden md:block">{t('courseribbon2')}</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 pl-2 text-black font-medium" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </a>
        </div>
      </div>
      {/* Navbar */}
      <nav
        ref={navbarRef}
        className="flex items-center justify-between px-8 py-3.5 text-sm font-normal bg-white relative shadow-lg"
      >
        <Link href="/#home" passHref>
          <a className="flex-shrink-0 mr-4"><img src="/images/dev-logo.png" alt="rec-logo" /></a>
        </Link>
        <div className="lg:hidden">
          <button
            type="button"
            onClick={navToggle}
            className={clsx('flex items-center text-blue-semilight p-1.5')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <div className={clsx('course-navigation', { active: navRes })}>
          <Link href="/#home" passHref>
            <a
              role="button"
              tabIndex={-1}
              onClick={navToggle}
              className={clsx('navbar-item course', { active: asPath.includes('#home') })}
            >
              Home
            </a>
          </Link>
          <Link href="/#learn" passHref>
            <a
              role="button"
              tabIndex={-1}
              onClick={navToggle}
              className={clsx('navbar-item course', { active: asPath.includes('#learn') })}
            >
              {t('learn')}
            </a>
          </Link>
          <Link href="/#challenge" passHref>
            <a
              role="button"
              tabIndex={-1}
              onClick={navToggle}
              className={clsx('navbar-item course', { active: asPath.includes('#challenge') })}
            >
              {t('challenge')}
            </a>
          </Link>
          <div className="flex-grow" />
          <div className="lg:mr-2 mt-2 lg:mt-0 lg:block grid">
            {profile && profile.role.name === 'student'
              ? (
                <AuthButton
                  profile={profile}
                  course
                />
              )
              : (
                <Link href={`${appUrl}/login?redirect=${fullUrl}`} passHref>
                  <a className="bg-blue-semilight text-white button">
                    {t('login')}
                  </a>
                </Link>
              )}
          </div>
          <div className={clsx('lg:mr-2 mt-2 lg:mt-0 grid', {
            hidden: profile && profile.role.name === 'student',
            'lg:block': !(profile && profile.role.name === 'student'),
          })}
          >
            <Link href={`${appUrl}/register`} passHref>
              <a target="_blank" className="bg-blue-dark text-white button">
                {t('regNow')}
              </a>
            </Link>
          </div>
          <div className="lg:mt-0 mt-2">
            <button
              ref={dropdownBtnRef}
              onClick={langToggle()}
              type="button"
              className={clsx('inline-flex items-center justify-between w-full xl:shadow-sm lg:px-2 px-3.5 py-2 focus:outline-none focus:ring-2 text-black')}
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              {locale?.toUpperCase()}
              <FiChevronDown className="ml-1" />
            </button>
            <div className="container relative">
              <div
                className={clsx('lg:absolute top-1 right-0 mt-2 lg:mt-4 lg:w-40 rounded-md lg:drop-shadow-lg bg-white focus:outline-none', {
                  'mb-4': lang,
                  hidden: !lang,
                })}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                ref={dropdownRef}
              >
                <div className="py-1" role="none">
                  <div
                    onClick={langToggle('en')}
                    role="button"
                    tabIndex={-1}
                    className="text-gray-700 block px-6 py-3 cursor-pointer hover:bg-gray-100"
                  >
                    English
                  </div>
                  <div
                    onClick={langToggle('id')}
                    role="button"
                    tabIndex={-2}
                    className="text-gray-700 block px-6 py-3 cursor-pointer hover:bg-gray-100"
                  >
                    Indonesia
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default CourseNavbar;
