import React, {
  FunctionComponent,
  useMemo,
  useRef,
  useState,
} from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { courseDomain } from '@configs';
import { useOutsideClick } from '@helpers/hooks';
import Button from '@component/Button';
import Dropdown from '@component/Dropdown';
import RibbonAuth from '@component/RibbonAuth';

export interface NavbarProps {
  lightMode?: boolean;
  hideNavbar?: boolean;
  fixedNav?: boolean;
}

const Navbar: FunctionComponent<NavbarProps> = (props) => {
  const { hideNavbar } = props;
  const { t } = useTranslation('common');
  const { asPath } = useRouter();

  // Language dropdown
  const [lang, setlang] = useState(false);

  // Dropdown
  const dropdownRef = useRef({} as HTMLDivElement);
  const dropdownBtnRef = useRef({} as HTMLButtonElement);
  useOutsideClick([dropdownRef, dropdownBtnRef], () => {
    if (lang) {
      setlang(false);
    }
  }, [lang]);

  // Navigation Menu Dropdown
  const [navRes, setNavRes] = useState(false);
  const navToggle = () => setNavRes((prevState) => !prevState);

  const featureItems = useMemo(() => {
    const items = [
      { label: t('codingTest'), link: '/features/coding-test' },
      { label: t('takeHome'), link: '/features/take-home-test' },
      { label: t('liveCoding'), link: '/features/live-coding' },
      { label: t('preRecordInterview'), link: '/features/interview-test' },
      { label: t('competitionBased'), link: '/features/competition-hiring' },
      { label: t('excelTest'), link: '/features/excel-test' },
      { label: t('interviewService'), link: '/features/interview-service' },
      { label: t('psychoTest'), link: '/features/psychometric-test' },
    ];

    return items;
  }, [t]);

  const resourceItems = useMemo(() => {
    const items = [
      { label: t('testimonials'), link: '/testimonials' },
      { label: t('ourStory'), comingSoon: true },
      { label: t('newsPress'), comingSoon: true },
      { label: 'Blog', link: 'https://algobash.com/blog', isExternal: true },
      { label: 'FAQ', link: 'https://faq.algobash.com/docs', isExternal: true },
      { label: t('privacyPolicy'), link: '/privacy-policy' },
      { label: t('termsAndCondition'), link: '/terms-and-conditions' },
    ];

    return items;
  }, [t]);

  const caseStudiesItems = useMemo(() => {
    const items = [
      { label: t('startups'), link: '/study-cases/startups' },
      { label: t('corporate'), link: '/study-cases/corporates' },
      { label: t('bank'), link: '/study-cases/banks' },
      { label: t('headhunt'), link: '/study-cases/headhunters-and-recruitment-agency' },
      { label: t('outsource'), link: '/study-cases/outsourcing-companies' },
      { label: t('bootcamp'), link: '/study-cases/education-sectors' },
    ];

    return items;
  }, [t]);

  return (
    <div className="w-full z-50 fixed">
      {/* Ribbon */}
      <div className="bg-[#092A47]">
        <div className="container container-padding mx-auto flex justify-center items-center text-sm py-2">
          <div className="flex justify-start items-center mr-2">
            <p className="font-semibold mb-0 text-white p-1 md:block hidden">
              {t('ribbon1')}
            </p>
            <p className="font-normal mb-0 tracking-normal text-white ml-2">
              {'💻 '}
              {t('ribbon3')}
            </p>
          </div>
          <a href={courseDomain} className="text-[#EEFF41] px-1 flex items-center" rel="noreferrer">
            <span className="font-semibold pr-0 md:pr-1">
              {`${t('visit')} `}
            </span>
            <span className="font-semibold hidden md:block">{t('ribbon2')}</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 pl-2 font-medium" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </a>
        </div>
      </div>
      {/* Ribbon 2 */}
      <div className="bg-[#EAEAEA] md:block hidden">
        <RibbonAuth />
      </div>
      {/* Navbar */}
      <div className={clsx('py-0 xl:py-5 text-sm font-normal bg-white', {
        hidden: hideNavbar,
      })}
      >
        <nav className="container container-padding mx-auto flex items-center justify-between flex-wrap">
          <div className="flex items-center flex-shrink-0 text-white mr-2 xl:mr-4">
            <Link href="/" passHref>
              <a><img src="/images/rec-logo.svg" alt="Algobash Recruiter" /></a>
            </Link>
          </div>
          <div className="block xl:hidden">
            <button
              type="button"
              onClick={navToggle}
              className="flex items-center py-2 text-black button-hamburger"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <div className={clsx('w-full block flex-grow xl:flex xl:items-center xl:w-auto lg:pl-3 lg:pb-2 lg:p-0 rounded-b-md', { 'h-0 hidden': !navRes })}>
            <div className="xl:mr-auto mt-2 xl:mt-0 md:flex">
              <>
                <Dropdown
                  name="Features"
                  items={featureItems}
                />
                <Dropdown
                  name="Case Studies"
                  items={caseStudiesItems}
                />
                <Dropdown
                  name="Resources"
                  items={resourceItems}
                />
                <Link href="/pricing" passHref>
                  <a className={clsx('navbar-item', { active: asPath.startsWith('/pricing') })}>
                    {t('pricing')}
                  </a>
                </Link>
              </>
            </div>
            <div className="mb-3 xl:-mb-2 xl:mr-3">
              <Button
                type="button-blue"
                target={false}
                buttonLink="/request-demo"
                buttonText={t('requestDemo')}
                customClass="w-full"
              />
            </div>
            <div className="mb-3 xl:-mb-2 xl:mr-3">
              <Button
                type="button-red"
                target={false}
                buttonLink="/free-trial "
                buttonText={t('freeTrial')}
                customClass="w-full"
              />
            </div>
            <div className="block md:hidden">
              <RibbonAuth />
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
