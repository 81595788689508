import { useOutsideClick } from '@helpers/hooks';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

interface DropdownItems {
  label: ReactNode;
  link?: string;
  comingSoon?: boolean;
  onClick?(): boolean | void;
  isExternal?: boolean;
}

interface Props {
  name: string;
  items: DropdownItems[];
  hideOnRouteChange?: boolean;
}

const Dropdown: FunctionComponent<Props> = (props) => {
  const {
    items,
    name,
    hideOnRouteChange = true,
  } = props;

  const { t } = useTranslation('common');
  const router = useRouter();
  const [open, setOpen] = useState(false);

  // Dropdown
  const dropdownRef = useRef({} as HTMLDivElement);
  const dropdownBtnRef = useRef({} as HTMLButtonElement);
  useOutsideClick([dropdownRef, dropdownBtnRef], () => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  useEffect(() => {
    if (open && hideOnRouteChange) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideOnRouteChange, router.pathname]);

  return (
    <div className="container relative">
      <button
        ref={dropdownBtnRef}
        onClick={() => setOpen(!open)}
        type="button"
        className="!flex items-center navbar-item p-0"
      >
        {name}
        <svg
          className="ml-2 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={clsx('xl:absolute left-0 top-12 mt-0 xl:mt-4 xl:w-64 rounded-md lg:drop-shadow-lg bg-white focus:outline-none xl:py-2 py-0 px-2', {
          'mb-4': open,
          hidden: !open,
        })}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        ref={dropdownRef}
      >
        {items.map((item) => {
          const {
            label,
            onClick,
            comingSoon,
            link,
            isExternal,
          } = item;

          function handleOnClick(e: MouseEvent) {
            e.preventDefault();
            if (onClick) {
              const r = onClick();
              setOpen(r ?? false);
            }
          }

          if (link) {
            return (
              <Link
                key={`drop-link-${label}`}
                href={link}
                locale={isExternal ? false : undefined}
              >
                <a className="font-normal text-gray-700 block px-3 py-2 text-base cursor-pointer hover:bg-gray-100">
                  {label}
                </a>
              </Link>
            );
          }

          return (
            <div
              key={`drop-${label}`}
              onClick={handleOnClick}
              role="button"
              tabIndex={-1}
              className={clsx('font-normal text-gray-700 block px-3 py-2 text-base', {
                'cursor-pointer hover:bg-gray-100': onClick,
                'cursor-default': !onClick,
              })}
            >
              {label}
              {comingSoon && (
                <span className="font-medium text-[#DFA50F] opacity-100 text-xs">
                  {' '}
                  {t('comingSoon')}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Dropdown;
