import Modal from '@component/Modal';
import { analyticsId, gtagId } from '@configs';
import { Disclosure, Switch, Transition } from '@headlessui/react';
import { ConfigType, useAlertConfig, useCookieConfig } from '@reducers/cookie';
import clsx from 'clsx';
import Link from 'next/link';
import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { FiHelpCircle, FiX } from 'react-icons/fi';
import Swal from 'sweetalert2';

interface CookieToggleProps {
  title: string;
  description: string;
  enabled?: boolean;
  onChange?(val: boolean): void;
  cookieList?: {
    name: string;
    desc: string;
  }[];
}

const CookieToggle: FC<CookieToggleProps> = (props) => {
  const {
    title,
    description,
    enabled = false,
    onChange,
    cookieList,
  } = props;

  const defaultFunc = () => { };

  return (
    <div className="mb-3">
      <Disclosure>
        <div className="flex items-center justify-between">
          <Disclosure.Button className="p-0 flex items-center cursor-pointer text-sm gap-1">
            <p className="font-medium">{title}</p>
            <FiHelpCircle size={13} />
          </Disclosure.Button>
          <Switch
            checked={enabled}
            onChange={onChange || defaultFunc}
            className={clsx(
              'relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2',
              'border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
              'focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 p-0 hover:opacity-100',
              {
                'bg-blue': enabled,
                'bg-gray-400': !enabled,
                'cursor-pointer': onChange,
                'cursor-not-allowed': !onChange,
              },
            )}
          >
            <span className="sr-only">Toggle Cookie</span>
            <span
              aria-hidden="true"
              className={clsx(
                'pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full bg-white',
                'shadow-lg ring-0 transition duration-200 ease-in-out',
                { 'translate-x-5': enabled, 'translate-x-0': !enabled },
              )}
            />
          </Switch>
        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-100 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel className="mt-1">
            <p className="text-sm font-normal text-gray-500">{description}</p>
            {cookieList && (
              <table className="cookie-table mt-1">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {cookieList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.desc}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    </div>
  );
};

const CookieConsent: FC = () => {
  const [alert, setAlert] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const onToggleModal = () => {
    if (!alert) setOpen(!isOpen);
  };

  const [config, setConfig] = useState<ConfigType>({
    ga: true,
    tawk: true,
  });

  const [hidden, setHidden] = useAlertConfig();
  const [cookieConf, setCookieConf] = useCookieConfig();

  const onClickToggle = (id: keyof ConfigType) => async (val: boolean) => {
    if (id === 'tawk' && !val) {
      setAlert(true);
      const res = await Swal.fire({
        title: 'Functional Cookie',
        text: 'This cookie enables Admin Support chat (via Tawk.to) by restricting this cookie you won\'t able to utilize this feature. Do you really want to turn this off ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes please',
        cancelButtonText: 'No, keep it!',
      });
      setAlert(false);

      if (!res.value) {
        return;
      }
    }

    setConfig({ ...config, [id]: val });
  };

  const onConfirmSettings = () => {
    if (!config.tawk) {
      document.cookie.split(';').map((i) => i.trim()).forEach((c) => {
        const [n] = c.split('=');
        if (n.toLowerCase().includes('twk') || n.toLowerCase().includes('tawk')) {
          document.cookie = `${c} expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      });
    }

    setCookieConf({ ...config });
    window.location.reload();
  };

  /**
   * Initiate tawk
   */
  const isTawkActive = typeof cookieConf === 'undefined' || cookieConf.tawk;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isTawkActive) {
        const s1 = document.createElement('script');
        const s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/63d712b94742512879104c76/1go0229f9';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        if (s0.parentNode) {
          s0.parentNode.insertBefore(s1, s0);
        }
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [isTawkActive]);

  /**
   * Initiate google analytics
   */
  const isGaActive = typeof cookieConf === 'undefined' || cookieConf.ga;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isGaActive) {
        const root = document.getElementsByTagName('script')[0];

        const s1 = document.createElement('script');
        s1.async = true;
        s1.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;

        const s2 = document.createElement('script');
        s2.text = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${analyticsId}');`;

        const s3 = document.createElement('script');
        s3.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtagId}');`;

        if (root.parentNode) {
          root.parentNode.insertBefore(s1, root);
          root.parentNode.insertBefore(s2, root);
          root.parentNode.insertBefore(s3, root);
        }
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [isGaActive]);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onToggleModal}
      >
        <div className="bg-white md:px-8 md:pt-8 px-6 pt-6 mb-0 relative">
          <button
            type="button"
            className="absolute right-5 top-4 p-0"
            onClick={() => setOpen(!isOpen)}
          >
            <FiX size={18} />
          </button>
          <h3 className="text-center text-xl leading-6 font-medium text-blue mb-2">
            Cookie Settings
          </h3>
          <p className="text-sm mb-3">
            When you visit any of our websites, it may store or retrieve information
            on your browser, mostly in the form of cookies. This information might be
            about you, your preferences or your device and is mostly used to make the
            site work as you expect it to. The information does not usually directly
            identify you, but it can give you a more personalized web experience.
            Because we respect your right to privacy, you can choose not to allow
            some types of cookies. Click on the different category headings to find out
            more and manage your preferences. Please note, blocking some types of cookies
            may impact your experience of the site and the services we are able to offer.
          </p>
          <CookieToggle
            enabled
            title="Strictly Necessary"
            description="These cookies are necessary for our website to function properly and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms or where they’re essential to provide you with a service you have requested. You cannot opt-out of these cookies. You can set your browser to block or alert you about these cookies, but if you do, some parts of the site will not then work. These cookies do not store any personally identifiable information."
            cookieList={[
              { name: 'mtg_state', desc: 'Used for user authentication' },
              { name: 'refresh_mtg_state', desc: 'Used for user authentication' },
              { name: 'connect_sid', desc: 'Application session ID' },
            ]}
          />
          <CookieToggle
            enabled={config.ga}
            onChange={onClickToggle('ga')}
            title="Performance Cookie"
            description="These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site, which helps us optimize your experience. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not be able to use your data in this way."
            cookieList={[
              { name: '_ga', desc: 'Used to identify unique users' },
              { name: '_gid', desc: 'Used to store information for Analytics Report' },
              { name: '_gcl_au', desc: 'Used to store and track conversions' },
              { name: '_gat_gtag', desc: 'Used for Google Tag Manager' },
            ]}
          />
          <CookieToggle
            enabled={config.tawk}
            onChange={onClickToggle('tawk')}
            title="Functional Cookies"
            description="These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly."
            cookieList={[
              { name: 'tawk_uuid_*', desc: 'Manages visitor data' },
              { name: 'twk_idm_key', desc: 'Manages visitor connection' },
              { name: 'TawkConnectionTime', desc: 'Manages visitor connection' },
            ]}
          />
        </div>
        <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:justify-end mb-0">
          <button
            type="button"
            className="my-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-red-500 font-medium focus:outline-none sm:w-auto sm:text-sm"
            onClick={onToggleModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="my-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onConfirmSettings}
          >
            Confirm
          </button>
        </div>
      </Modal>

      <div
        className={clsx('fixed bottom-0 z-10 bg-blue-dark text-white px-4 py-3 w-full', {
          'md:pr-32 xl:pl-32': isTawkActive,
          hidden,
        })}
      >
        <div className="container container-padding mx-auto flex items-center justify-center">
          <p className="hidden sm:block text-xs">
            Our website stores cookies on your device and discloses
            information in accordance with our
            {' '}
            <Link locale="en" href="/privacy-policy">
              <a
                href="/privacy-policy"
                className="underline"
              >
                Privacy Policy
              </a>
            </Link>
            .
          </p>
          <button
            type="button"
            className="p-2 px-3 text-xs whitespace-nowrap md:ml-auto border-1 border-white"
            onClick={onToggleModal}
          >
            Cookie Settings
          </button>
          <button
            type="button"
            className="p-0 self-start mx-1"
            onClick={() => setHidden(true)}
          >
            <FiX />
          </button>
        </div>
      </div>
    </>
  );
};

export default CookieConsent;
