import Button from '@component/Button';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, {
  FunctionComponent,
  MouseEvent,
} from 'react';

import { teamUrl, courseDomain, appUrl } from '@configs';

interface CSProps {
  title: string;
  comingSoon?: boolean;
  link?: string;
  externalLink?: string;
}

const FooterItem: FunctionComponent<CSProps> = (props) => {
  const {
    title,
    link,
    externalLink,
    comingSoon,
  } = props;

  const { t } = useTranslation();

  const item = (
    <p className={clsx('font-normal text-sm opacity-100 py-2', { 'leading-none ': comingSoon })}>
      <span className="opacity-70">{title}</span>
      {comingSoon && (
        <span
          className="font-medium text-[#DFA50F] opacity-100 text-xs"
        >
          {' '}
          {t('comingSoon')}
        </span>
      )}
    </p>
  );

  if (link) {
    return (
      <div className="flex">
        <Link href={link} passHref>
          <a className="hover:opacity-100">
            {item}
          </a>
        </Link>
      </div>
    );
  }

  if (externalLink) {
    return (
      <div className="flex">
        <a
          href={externalLink}
          className="hover:opacity-100"
        >
          {item}
        </a>
      </div>
    );
  }

  return item;
};

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  /**
   * Handle copy email click
   */
  const onClickCopyEmail = (e: MouseEvent) => {
    e.preventDefault();
    const email = 'aW5mb0BhbGdvYmFzaC5jb20=';
    const text = Buffer.from(email, 'base64');
    navigator.clipboard.writeText(text.toString());
  };

  return (
    <footer className="h-full w-full bg-blue-dark">
      <div className="flex flex-col md:flex-row container container-padding mx-auto py-10 pb-20 sm:items-start">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M36.304 4.05666C38.2872 1.87989 41.7128 1.87989 43.696 4.05666L46.1234 6.7209C47.439 8.16492 49.4752 8.7105 51.3365 8.11776L54.7708 7.02415C57.5768 6.13063 60.5434 7.84339 61.1725 10.7202L61.9426 14.2412C62.3599 16.1495 63.8505 17.6401 65.7588 18.0574L69.2798 18.8275C72.1566 19.4566 73.8694 22.4232 72.9759 25.2292L71.8822 28.6635C71.2895 30.5248 71.8351 32.561 73.2791 33.8766L75.9433 36.304C78.1201 38.2872 78.1201 41.7128 75.9433 43.696L73.2791 46.1234C71.8351 47.439 71.2895 49.4752 71.8822 51.3365L72.9759 54.7708C73.8694 57.5768 72.1566 60.5434 69.2798 61.1725L65.7589 61.9426C63.8505 62.3599 62.3599 63.8505 61.9426 65.7588L61.1725 69.2798C60.5434 72.1566 57.5768 73.8694 54.7708 72.9759L51.3365 71.8822C49.4752 71.2895 47.439 71.8351 46.1234 73.2791L43.696 75.9433C41.7128 78.1201 38.2872 78.1201 36.304 75.9433L33.8766 73.2791C32.561 71.8351 30.5248 71.2895 28.6635 71.8822L25.2292 72.9759C22.4232 73.8694 19.4567 72.1566 18.8275 69.2798L18.0574 65.7589C17.6401 63.8505 16.1495 62.3599 14.2412 61.9426L10.7202 61.1725C7.84339 60.5434 6.13063 57.5768 7.02415 54.7708L8.11776 51.3365C8.7105 49.4752 8.16492 47.439 6.72091 46.1234L4.05666 43.696C1.87989 41.7128 1.87989 38.2872 4.05666 36.304L6.7209 33.8766C8.16492 32.561 8.7105 30.5249 8.11776 28.6635L7.02415 25.2292C6.13063 22.4232 7.84339 19.4567 10.7202 18.8275L14.2412 18.0574C16.1495 17.6401 17.6401 16.1495 18.0574 14.2412L18.8275 10.7202C19.4566 7.8434 22.4232 6.13063 25.2292 7.02415L28.6635 8.11776C30.5248 8.7105 32.561 8.16492 33.8766 6.72091L36.304 4.05666Z" fill="#7DBDF9" />
          <path d="M36.6736 9.3934C38.4585 7.4343 41.5415 7.43431 43.3264 9.3934L44.7185 10.9214C46.1657 12.5098 48.4054 13.1099 50.453 12.4579L52.4226 11.8307C54.9479 11.0265 57.6178 12.568 58.1841 15.1571L58.6257 17.1764L59.1141 17.0696L58.6257 17.1764C59.0848 19.2756 60.7244 20.9152 62.8236 21.3743L64.8429 21.8159C67.432 22.3822 68.9735 25.0521 68.1693 27.5775L67.5421 29.547C66.8901 31.5946 67.4902 33.8343 69.0787 35.2815L70.6066 36.6736C72.5657 38.4585 72.5657 41.5415 70.6066 43.3264L69.0787 44.7185C67.4902 46.1657 66.8901 48.4054 67.5421 50.453L68.1693 52.4226C68.9735 54.9479 67.432 57.6178 64.8429 58.1841L62.8236 58.6257C60.7244 59.0848 59.0848 60.7244 58.6257 62.8236L58.1841 64.8429C57.6178 67.432 54.9479 68.9735 52.4225 68.1693L50.453 67.5421C48.4054 66.8901 46.1657 67.4902 44.7185 69.0787L43.3264 70.6066C41.5415 72.5657 38.4585 72.5657 36.6736 70.6066L35.2815 69.0786C33.8343 67.4902 31.5946 66.8901 29.547 67.5421L27.5775 68.1693C25.0521 68.9735 22.3822 67.432 21.8159 64.8429L21.3743 62.8236C20.9152 60.7244 19.2756 59.0848 17.1764 58.6257L15.1571 58.1841C12.568 57.6178 11.0265 54.9479 11.8307 52.4225L12.4579 50.453C13.1099 48.4054 12.5098 46.1657 10.9214 44.7185L9.3934 43.3264C7.4343 41.5415 7.43431 38.4585 9.3934 36.6736L10.9214 35.2815C12.5098 33.8343 13.1099 31.5946 12.4579 29.547L11.8307 27.5774C11.0265 25.0521 12.568 22.3822 15.1571 21.8159L17.1764 21.3743C19.2756 20.9152 20.9152 19.2756 21.3743 17.1764L21.8159 15.1571C22.3822 12.568 25.0521 11.0265 27.5775 11.8307L29.547 12.4579C31.5946 13.1099 33.8343 12.5098 35.2815 10.9214L36.6736 9.3934Z" stroke="#092A47" />
          <path d="M37.6176 49.1707C38.5465 47.8122 40.0861 46.9998 41.7319 46.9998H50.9995V52.9998H34.9995L37.6176 49.1707Z" fill="#092A47" />
          <path d="M42.0856 29.0866C43.0212 27.7771 44.5316 27 46.1409 27H51L32.4235 53H25L42.0856 29.0866Z" fill="#092A47" />
        </svg>
        <div className="md:ml-4">
          <p className="opacity-100">
            Copyright ©
            {' '}
            {(new Date().getFullYear())}
            {' '}
            Algobash
          </p>
          <p className="font-normal">
            {t('footer-1')}
            {' '}
            {' '}
            PT Matagi Informasi Pertiwi
          </p>
          <p className="font-normal">Indonesia</p>
          <a
            target="_blank"
            className="underline font-normal block opacity-50 hover:opacity-100"
            href="https://wa.me/6285157707484/?text=Hi+Algobash%2C+I+want+to+ask+a+question"
            rel="noreferrer"
          >
            <span>+</span>
            <span>62815</span>
            <span>-</span>
            <span>1577</span>
            <span>-</span>
            <span>07484</span>
          </a>
          <a
            href="#copy-email"
            className="underline font-normal block opacity-50 hover:opacity-100"
            onClick={onClickCopyEmail}
            role="button"
          >
            info
            <span className="text-[10px]">(at)</span>
            algobash
            <span className="text-[10px]">(dot)</span>
            com
          </a>
          <div className="bg-white inline-block p-1.5 rounded mt-1.5">
            <img src="/images/kominfo-logo.png" alt="kominfo logo" />
          </div>
        </div>
        <div className="xl:ml-auto md:ml-4 md:max-w-[170px]">
          <p className="opacity-100">{t('footer-2')}</p>
          <FooterItem
            title={t('codingCourses')}
            link={courseDomain}
          />
          <FooterItem
            title={t('challenges')}
            comingSoon
          />
          <Button
            type="button-white"
            buttonText={t('participantLogin')}
            buttonLink={appUrl}
            customClass="opacity-100 mt-1"
            target={false}
          />
          <p className="opacity-100 mt-5">{t('footer-3')}</p>
          <FooterItem
            title={t('requestDemo')}
            link="/request-demo"
          />
          <FooterItem
            title={t('pricing')}
            link="/pricing"
          />
          <FooterItem
            title={t('freeTrial')}
            link="/free-trial"
          />
          <Button
            type="button-green-sm"
            buttonText={t('recruiterLogin')}
            buttonLink={teamUrl}
            customClass="opacity-100 mt-1 py-2"
            target={false}
          />
        </div>
        <div className="md:ml-4 md:max-w-[170px]">
          <p className="opacity-100 md:mt-0 mt-5">Explore Our Feature</p>
          <FooterItem title={t('codingTest')} link="/features/coding-test" />
          <FooterItem title={t('takeHome')} link="/features/take-home-test" />
          <FooterItem title={t('liveCoding')} link="/features/live-coding" />
          <FooterItem title={t('preRecordInterview')} link="/features/interview-test" />
          <FooterItem title={t('competitionBased')} link="/features/competition-hiring" />
          <FooterItem title={t('excelTest')} link="/features/excel-test" />
          <FooterItem title={t('interviewService')} link="/features/interview-service" />
          <FooterItem title={t('psychoTest')} link="/features/psychometric-test" />
        </div>
        <div className="md:ml-4 md:max-w-[170px]">
          <p className="opacity-100 md:mt-0 mt-5">{t('footer-5')}</p>
          <FooterItem title={t('startups')} link="/study-cases/startups" />
          <FooterItem title={t('corporate')} link="/study-cases/corporates" />
          <FooterItem title={t('bank')} link="/study-cases/banks" />
          <FooterItem title={t('headhunt')} link="/study-cases/headhunters-and-recruitment-agency" />
          <FooterItem title={t('outsource')} link="/study-cases/outsourcing-companies" />
          <FooterItem title={t('bootcamp')} link="/study-cases/education-sectors" />
        </div>
        <div className="md:ml-4 md:max-w-[170px]">
          <p className="opacity-100 md:mt-0 mt-5">Resources</p>
          <FooterItem title={t('testimonials')} link="/testimonials" />
          <FooterItem title={t('ourStory')} comingSoon />
          <FooterItem title={t('newsPress')} comingSoon />
          <FooterItem title="Blog" externalLink="https://algobash.com/blog" />
          <FooterItem title="FAQ" link="https://faq.algobash.com/docs" />
          <FooterItem title={t('privacyPolicy')} link="/privacy-policy" />
          <FooterItem title={t('termsAndCondition')} link="/terms-and-conditions" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
